import { Injectable, inject } from '@angular/core';
import { DialogService as PrimeDialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, Subject, asyncScheduler, delay, filter, first, merge, observeOn, switchMap, takeUntil, tap, timer } from 'rxjs';
import { FileImporterComponent } from '../file-importer/file-importer.component';
import { DialogComponent } from './dialog.component';
import { ConfirmDialogComponentProperties, DialogComponentProperties, FileImporterComponentProperties } from '@mca/shared/domain';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private primeDialogService = inject(PrimeDialogService);

  confirmDialogReference?: DynamicDialogRef;
  awaitingCloseConfirmation = false;

  get dialogReference() {
    const [lastReference] = [...this.primeDialogService.dialogComponentRefMap].at(-1) || [];
    return lastReference;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  open<T = any>(properties?: Partial<DialogComponentProperties>): Observable<T> {
    // wait for possible previous dialog to be destroyed
    return timer(0).pipe(
      tap(() => {
        this.primeDialogService.open(properties?.component ?? DialogComponent, {
          showHeader: false,
          styleClass: 'modal-dialog ' + (properties?.styleClass ?? ''),
          duplicate: true,
          data: { ...(properties?.data ?? {}), ...properties },
        });
      }),
      // wait for dialog reference to be created
      delay(0),
      tap(() => this.enableCloseConfirmation()),
      switchMap(() => this.getResult() as Observable<T>),
    );
  }

  getComponentInstance<T = DialogComponent>() {
    return (
      this.dialogReference &&
      (this.primeDialogService.dialogComponentRefMap.get(this.dialogReference)?.instance.componentRef?.instance as T)
    );
  }

  getResult() {
    const out$ = this.getComponentInstance()?.out$ ?? new Subject();
    // first() to avoid loop, when close is additionally called in subscriptions
    const close$ = this.dialogReference?.onClose.pipe(first()) as Observable<unknown>;
    const destroy$ = this.dialogReference?.onDestroy.pipe(observeOn(asyncScheduler)) as Observable<unknown>;
    return merge(out$, close$).pipe(takeUntil(destroy$));
  }

  closeModal(result?: unknown, ref = this.dialogReference) {
    ref?.close(result);
  }

  stopLoading(): void {
    this.getComponentInstance()?.loading?.set(false);
  }

  confirm(properties?: Partial<ConfirmDialogComponentProperties>) {
    const message = properties?.message || `Are you sure you want to ${properties?.action || 'delete'} this ${properties?.targetName}?`;
    return this.open({ title: 'Confirm action', confirmLabel: 'Yes', cancelLabel: 'Continue', ...properties, message });
  }

  openUploader(properties: FileImporterComponentProperties) {
    return this.open({ component: FileImporterComponent, data: properties, styleClass: 'dialog__size-xl' });
  }

  private enableCloseConfirmation() {
    this.getComponentInstance()
      ?.closing$?.pipe(
        takeUntil(this.dialogReference?.onDestroy as Observable<unknown>),
        filter(result => !!result && !this.awaitingCloseConfirmation),
      )
      .subscribe(() => {
        this.awaitingCloseConfirmation = true;
        this.confirm({ message: 'Are you sure you want to cancel? All entered data so far will be lost.' }).subscribe(result => {
          this.awaitingCloseConfirmation = false;
          if (result) {
            [...this.primeDialogService.dialogComponentRefMap].forEach(([ref]) => ref.close());
          }
        });
      });
  }
}
